/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";

import "../layout.css";

const Layout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <footer
        className="__footer-bottom"
        style={{
          marginTop: `1rem`,
          marginBottom: `1rem`,
          textAlign: `center`,
          fontFamily: `Manrope`,
          color: `black`,
        }}
      >
        <a href="https://lovecoach.id/terms-of-use" target="_blank">
          Term Of Service
        </a>{" "}
        -{" "}
        <a href="https://lovecoach.id/privacy-policy" target="_blank">
          Privacy Policy
        </a>{" "}
        <br />
        Copyright © {new Date().getFullYear()}
        {` `}
        Jose Learning Center - ALL Rights Reserved
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
